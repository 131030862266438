.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  margin: 0;
}

#mainTitle {
  padding: 50px 0;
}

#mainTitle h1 {
  font-size: 12vw;
}

#mainTitle h2 {
  font-size: 6vw;
}

#mainTitle span {
  border-bottom: 2px solid currentColor;
  line-height: 0.85;
  display: inline-block;
}

h1,
h2,
h3 {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

h3 {
  text-align: left;
}

#navbar {
  margin: 0 auto;
}

#navbar a {
  color: grey;
  text-decoration: none;
}

#navbar a:hover {
  text-decoration: none;
  color: black;
}

.homeLink {
  color: black;
}

.homeLink span {
  border-bottom: 0.5px solid currentColor;
  line-height: 0.85;
  display: inline-block;
}

.homeLink:hover {
  color: grey;
}

.homeLink:hover span {
  border-bottom: none;
}

#nav-brand {
  float: left;
  padding: 20px;
}

ul {
  margin: 0;
}

#nav-links-w {
  display: none;
  float: right;
  padding-right: 50px;
}

#nav_menu-toggle {
  position: absolute;
  background-image: url("/menu.svg");
  margin: 5px;
  height: 32px;
  width: 32px;
  right: 5px;
  top: 7px;
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

#nav-links-n #nav-links {
  clear: both;
}

#nav-links ul {
  list-style: none;
}

#nav-links-w #nav-links ul li {
  float: left;
  padding: 20px;
}

#nav-links-n #nav-links ul li {
  float: right;
  padding: 10px 30px;
  clear: both;
}

.contact-icons {
  font-size: 2em;
  color: black;
}

#content {
  width: 70%;
  margin: 0 auto;
}

#content p {
  text-align: left;
}

#contactBar {
  width: 70%;
  margin: 0 auto;
}

#contactBar p {
  text-align: left;
}

.clearBorder {
  clear: both;
  width: 100%;
  border-top: 1px solid grey;
}

.contactIcon {
  height: 2em;
  width: 2em;
  color: black;
}

.contactIcon:hover {
  color: grey;
}

#abstract {
  border-left: 5px solid lightgray;
}

#abstract p {
  margin-left: 15px;
}

#latestList {
  text-align: left;
}

#abstractButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.jobTitle {
  font-weight: bold;
}

.jobDates {
  padding-left: 20px;
}

ul,
ol {
  text-align: left;
}

.indentList {
  padding-left: 20px;
}

@media only screen and (min-width: 850px) {
  #navbar {
    width: 70%;
  }

  #mainTitle h1 {
    font-size: 6vw;
  }

  #mainTitle h2 {
    font-size: 4vw;
  }

  #nav-links-w {
    display: block;
  }

  #nav-links-n {
    display: none;
  }
}
